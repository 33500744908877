<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingUsers">
              <div class="table-responsive mb-0">
                <b-table :items="users" :fields="datatable.columns">
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(actions)="data">
                    <b-button
                      variant="primary"
                      size="sm"
                      title="Sign In"
                      :disabled="loadingImpersonate"
                      @click="impersonate(data.item.id)"
                    >
                      <b-spinner v-if="loadingImpersonate" small />
                      <i class="uil uil-sign-out-alt"></i>
                    </b-button>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalUsers"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#' },
          { key: 'full_name' },
          { key: 'role' },
          { key: 'email' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
        },
      },
      loadingUsers: false,
      loadingImpersonate: false,
    }
  },

  computed: {
    users() {
      return this.$store.getters['user/all'] || []
    },

    totalUsers() {
      return this.$store.getters['user/totalUsers']
    },
  },

  mounted() {
    if (this.$store.getters['user/all'] === null) {
      this.getUsers()
    } else {
      this.datatable.queries = this.$store.getters['user/indexQueries']
    }
  },

  methods: {
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getUsers()
    },

    getUsers() {
      this.loadingUsers = true

      this.$store
        .dispatch('user/getAll', this.datatable.queries)
        .then((res) => {
          this.loadingUsers = false
        })
        .catch(() => {
          this.loadingUsers = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },
  },
}
</script>
